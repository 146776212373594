<template>
  <div>...</div>
</template>

<script>
export default {
  mounted() {
    this.$router.push("/my-subscription");
  },
};
</script>

<style></style>
